import Animate from 'components/atoms/animate'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

import Container from '../container'

const StyledFeatureImage = styled.div`
  ${({ theme }): CSSProp => css`
    margin: 5rem 0;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      margin: 7rem 0;
    }
    h1 {
      position: relative;
      font-size: 5rem;
      color: ${theme.colours.tertiary};
      font-family: ${theme.font.fontFamilyAlt};
      margin: 0 0 0 2rem;
      line-height: 1.11;
      max-width: 30rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        margin: 0;
        font-size: 7rem;
        position: absolute;
        left: 50%;
        bottom: 12%;
        max-width: 60rem;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: 9rem;
        bottom: 15%;
      }
    }
    .image {
      position: relative;
      margin: 0 0 5rem -2rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        margin: 0 0 9.5rem;
      }
      .gatsby-image-wrapper {
        margin-bottom: -2.8rem;
        @media only screen and ${theme.breakpoints.fromNormalScreen} {
          width: 60%;
          margin: 0 0 0 -4rem;
        }
        @media only screen and ${theme.breakpoints.fromLargeScreen} {
          margin: 0;
        }
      }
    }
  `}
`

type FeatureImageProps = {
  title: string
  image: {
    image: FluidObject
    imageAlt: string
  }
}

const FeatureImage = ({ title, image }: FeatureImageProps): JSX.Element => {
  const { image: imageObj, imageAlt } = image
  return title && imageObj ? (
    <Animate>
      <StyledFeatureImage>
        <Container>
          <div className="image">
            {imageObj && <Img alt={imageAlt} fluid={imageObj} />}
            <h1>{title}</h1>
          </div>
        </Container>
      </StyledFeatureImage>
    </Animate>
  ) : null
}

export default FeatureImage
