import Animate from 'components/atoms/animate'
import Img from 'gatsby-image'
import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled, { css, CSSProp } from 'styled-components'

import Arrow from '../../images/svg/arrow-right.svg'
import { ContentfulTestimonial } from '../../types/graphql-types'

const StyledPullQuote = styled.div`
  ${({ theme }): CSSProp => css`
    text-align: center;
    max-width: 94rem;
    margin: auto;

    .gatsby-image {
      display: inline-block;
      width: 6rem;
      margin-bottom: 1.5rem;
      img {
        border-radius: 50%;
      }
    }
    .author-name {
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 1.2px;
      font-weight: ${theme.font.fontWeightBold};
      padding: 0 2rem;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        font-size: 12px;
      }
    }
    blockquote {
      padding: 0 2rem;
      font-size: 22px;
      font-family: ${theme.font.fontFamilyAlt};
      line-height: 1.45;
      margin: 3rem 0 0;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        font-size: 24px;
        font-weight: ${theme.font.fontWeightBold};
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: 30px;
      }
    }
  `}
`
const StyledPullQuotes = styled.div`
  ${({ theme }): CSSProp => css`
    position: relative;
    margin: 15rem 0;
    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      margin-bottom: 10rem;
    }
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      margin-bottom: 15rem;
    }
    .button-prev,
    .button-next {
      color: ${theme.colours.tertiary};
      background: none;
      padding: 1rem;
      border: none;
      position: absolute;
      top: 1.2rem;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      cursor: pointer;
      svg {
        width: 2.2rem;
      }
    }
    .button-prev {
      margin-left: -16rem;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        margin-left: -18rem;
      }
      svg {
        transform: rotate(180deg);
      }
    }
    .button-next {
      margin-left: 16rem;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        margin-left: 18rem;
      }
    }
    .slick-dots {
      list-style: none;
      margin: 4rem 0 0;
      padding: 0;
      text-align: center;
      li {
        display: inline-block;
        margin: 0 1rem;
      }
      button {
        font-size: 0;
        background: none;
        border: solid #26140f 1px;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        padding: 0;
      }
      .slick-active {
        button {
          background: #26140f;
        }
      }
    }
  `}
`

const PullQuote = ({
  byline,
  authorImage,
  quote,
}: ContentfulTestimonial): JSX.Element => (
  <StyledPullQuote>
    <div className="gatsby-image">
      <Img fluid={authorImage.fluid} />
    </div>
    <div className="author-name">{byline}</div>
    <blockquote>{quote}</blockquote>
  </StyledPullQuote>
)

const PullQuotes = ({
  items,
}: {
  items: ContentfulTestimonial[]
}): JSX.Element => {
  const sliderRef = useRef<Slider>()
  const prevSlide = (): void => {
    sliderRef.current.slickPrev()
  }
  const nextSlide = (): void => {
    sliderRef.current.slickNext()
  }
  return items ? (
    <Animate>
      <StyledPullQuotes>
        <button
          className="button-prev"
          type="button"
          onClick={(): void => prevSlide()}
        >
          <Arrow />
        </button>
        <button
          className="button-next"
          type="button"
          onClick={(): void => nextSlide()}
        >
          <Arrow />
        </button>
        <Slider dots arrows={false} ref={sliderRef}>
          {items.map((item, k) => (
            <PullQuote key={`slide-${k}`} {...item} />
          ))}
        </Slider>
      </StyledPullQuotes>
    </Animate>
  ) : null
}

export default PullQuotes
