import Layout from 'components/layout'
import Seo from 'components/molecules/seo'
import Carousel from 'components/organisms/carousel'
import CTABlock from 'components/organisms/ctaBlock'
import FeatureImage from 'components/organisms/featureImage'
import Features from 'components/organisms/features'
import Hero from 'components/organisms/hero'
import PullQuotes from 'components/organisms/pullQuotes'
import Testimonial from 'components/organisms/testimonial'
import { graphql } from 'gatsby'
import { getLocalNodes } from 'helpers/locale'
import { useResourceMediaData } from 'hooks/useResourceMediaData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import * as React from 'react'
import styled from 'styled-components'
import {
  ContentfulArticleConnection,
  ContentfulCtaBlockConnection,
  ContentfulHeroPanelConnection,
  ContentfulResourceSetConnection,
  ContentfulTestimonialConnection,
  Site,
} from 'types/graphql-types'

const CarouselWrapper = styled.div`
  margin: 3rem 0;
  padding: 2rem 0;
`

type WholeSaleTypes = {
  data: {
    site: Site
    allContentfulHeroPanel: ContentfulHeroPanelConnection
    allContentfulCtaBlock: ContentfulCtaBlockConnection
    allContentfulResourceSet: ContentfulResourceSetConnection
    allContentfulArticle: ContentfulArticleConnection
    allContentfulTestimonial: ContentfulTestimonialConnection
    pullQuotes: ContentfulTestimonialConnection
    footerCtaBlock: ContentfulCtaBlockConnection
  }
}

const Wholesale = ({ data }: WholeSaleTypes): JSX.Element => {
  const heroData = getLocalNodes(data.allContentfulHeroPanel.nodes)
  const featuresData = getLocalNodes(data.allContentfulResourceSet.nodes)
  const ctaData = getLocalNodes(data.allContentfulCtaBlock.nodes)
  const articlesData = getLocalNodes(data.allContentfulArticle.nodes)
  const footerCtaBlock = getLocalNodes(data.footerCtaBlock.nodes)
  const testimonial = getLocalNodes(data.allContentfulTestimonial.nodes)
  const {
    byline: testimonialByline,
    authorImage: testimonialAuthorImage,
    images: testimonialImages,
    quote: testimonialQuote,
    body: testimonialBody,
    link: testimonialLink,
  } = testimonial[0]
  const pullQuotes = getLocalNodes(data.pullQuotes.nodes)

  return (
    <Layout header="trans">
      <Seo title="Wholesale" />
      <Hero {...heroData[0]} image={heroData[0].image.fluid} align="center" />

      <FeatureImage
        title={useResourceTextData(
          'wholesale.featureTitle',
          'What’s on offer?'
        )}
        image={useResourceMediaData('wholesale.featureImage')}
      />

      {featuresData[0] && <Features items={featuresData[0].resources} />}

      {ctaData[0] && (
        <CTABlock body={ctaData[0].body.body} cta={ctaData[0].cta} />
      )}

      {testimonial[0] && (
        <Testimonial
          byline={testimonialByline}
          authorImage={testimonialAuthorImage.fluid}
          images={testimonialImages.map(({ fluid }) => fluid)}
          quote={testimonialQuote}
          body={testimonialBody.body}
          link={testimonialLink}
        />
      )}

      {articlesData[0] && (
        <CarouselWrapper>
          <Carousel
            intro={useResourceTextData('wholesale.latestarticlesintro')}
            introCta={{
              text: useResourceTextData(
                'wholesale.latestarticlesbrowse',
                'Browse articles'
              ),
              to: '/community',
            }}
            items={articlesData}
          />
        </CarouselWrapper>
      )}

      <PullQuotes items={pullQuotes} />

      {footerCtaBlock[0] && (
        <CTABlock
          body={footerCtaBlock[0].body.body}
          cta={footerCtaBlock[0].cta}
          alternate
          noMarginPadding
        />
      )}
    </Layout>
  )
}

export default Wholesale

export const pageQuery = graphql`
  query WholeSaleQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHeroPanel(filter: { key: { eq: "wholesale.hero" } }) {
      nodes {
        id
        title
        subTitle
        image {
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        body {
          body
        }
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        node_locale
      }
    }

    allContentfulResourceSet(filter: { name: { eq: "Wholesale features" } }) {
      nodes {
        resources {
          ... on ContentfulFeature {
            id
            image {
              fluid {
                ...Image
              }
            }
            title
            body
          }
        }
        node_locale
      }
    }
    allContentfulCtaBlock(filter: { key: { eq: "wholesale.pullquote" } }) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        node_locale
      }
    }
    allContentfulTestimonial(filter: { key: { eq: "wholesale" } }) {
      nodes {
        body {
          body
        }
        node_locale
        quote
        authorImage {
          fluid {
            ...Image
          }
        }
        images {
          fluid {
            ...Image
          }
        }
        byline
        link {
          ... on Node {
            ... on ContentfulLink {
              ...Link
            }
            ... on ContentfulRegionalLink {
              ...RegionalLink
            }
          }
        }
      }
    }
    allContentfulArticle(sort: { order: DESC, fields: updatedAt }) {
      nodes {
        id
        slug
        heroImage {
          fluid {
            ...Image
          }
          title
        }
        node_locale
        title
        category {
          title
          slug
        }
      }
    }
    pullQuotes: allContentfulTestimonial(
      filter: { key: { eq: "wholesale.pullquote" } }
    ) {
      nodes {
        node_locale
        quote
        authorImage {
          fluid {
            ...Image
          }
        }
        byline
      }
    }
    footerCtaBlock: allContentfulCtaBlock(
      filter: { key: { eq: "wholesale.footerCta" } }
    ) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        node_locale
      }
    }
  }
`
