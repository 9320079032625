import Animate from 'components/atoms/animate'
import Container from 'components/container'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { H3 } from 'styles/base'

const StyledFeatures = styled.div`
  ${({ theme }): CSSProp => css`
    margin: 5rem 0;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      margin: 7rem 0;
    }
    h2 {
      text-align: center;
      font-size: 1.8rem;
      font-family: ${theme.font.fontFamilyAlt};
      margin: 0 0 6rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        font-size: 2.4rem;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: 3rem;
      }
    }
    .row {
      display: grid;
      grid-gap: 5rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        max-width: 70rem;
        margin: 0 auto;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 7rem;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        max-width: none;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 9rem;
      }
    }
  `}
`

const Feature = styled.div`
  ${({ theme }): CSSProp => css`
    text-align: center;
    font-size: ${theme.font.fontSizeBodyDefault};
    font-weight: ${theme.font.fontWeightLight};
    h3 {
      margin-bottom: 2.5rem;
    }
    .gatsby-image-wrapper {
      width: 8rem;
      margin: 0 auto 3rem;
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        margin-bottom: 5rem;
      }
    }
  `}
`

type FeaturesProps = {
  title?: string
  items: [
    {
      id: string
      image: {
        fluid: FluidObject
      }
      title: string
      body: string
    }
  ]
}

const Features = ({ title, items }: FeaturesProps): JSX.Element => {
  return (
    <Animate>
      <StyledFeatures>
        <Container>
          {title && <h2>{title}</h2>}
          <div className="row">
            {items.map(item => (
              <Feature key={item.id}>
                <Img fluid={item.image.fluid} />
                <H3>{item.title}</H3>
                <div>{item.body}</div>
              </Feature>
            ))}
          </div>
        </Container>
      </StyledFeatures>
    </Animate>
  )
}

export default Features
