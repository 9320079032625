import Animate from 'components/atoms/animate'
import Container from 'components/container'
import NavigationLink from 'components/molecules/navigation-link'
import Img, { FluidObject } from 'gatsby-image'
import { renderLineBreaks } from 'helpers/text'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { ContentfulLink } from 'types/graphql-types'

const StyledTestimonials = styled.div`
  ${({ theme }): CSSProp => css`
    position: relative;
    background-color: ${theme.colours.lightTint3};
    padding: 9rem 0;
    margin-bottom: 18rem;
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      margin: 10rem 0 0 0;
    }

    .content {
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        width: 50%;
      }
    }
    .author {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 1.2px;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        font-size: 12px;
      }
      .gatsby-image-wrapper {
        width: 4.5rem;
        margin-right: 2rem;
        @media only screen and ${theme.breakpoints.fromMediumScreen} {
          width: 6rem;
        }
      }
    }
    blockquote {
      padding: 0;
      margin: 3rem 0;
      font-family: ${theme.font.fontFamilyAlt};
      font-size: 22px;
      line-height: 1.45;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        font-size: 24px;
        font-weight: ${theme.font.fontWeightBold};
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: 30px;
      }
    }
    .body {
      font-size: 18px;
      font-weight: ${theme.font.fontWeightLight};
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        font-size: 16px;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        font-size: 18px;
      }
    }
    .link {
      display: inline-block;
      margin-top: 2.2rem;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: ${theme.font.fontWeightBold};
      letter-spacing: 1.2px;
      .icon {
        margin-right: 1rem;
        color: ${theme.colours.tertiary};
      }
    }
    .images {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      grid-gap: 1rem;
      width: 90%;
      margin: 6rem 0 -20rem auto;

      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        margin: 0;
        position: absolute;
        top: 7rem;
        bottom: 7rem;
        right: 0;
        grid-gap: 2.5rem;
        width: 42%;
        > .gatsby-image-wrapper:not(:first-child) {
          display: none;
        }
      }

      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        top: -10rem;
        bottom: auto;
        height: 90%;
        > .gatsby-image-wrapper:not(:first-child) {
          display: block;
        }
      }
    }
  `}
`

type TestimonialsProps = {
  byline: string
  authorImage: FluidObject
  images: FluidObject[]
  quote: string
  body: string
  link: ContentfulLink
}

const Testimonials = ({
  byline,
  authorImage,
  images,
  quote,
  body,
  link,
}: TestimonialsProps): JSX.Element => {
  return (
    <Animate>
      <StyledTestimonials>
        <Container narrow>
          <div className="content">
            <div className="author">
              <Img fluid={authorImage} />
              {byline}
            </div>
            <blockquote>{quote}</blockquote>
            <div className="body">{renderLineBreaks(body)}</div>

            {link && (
              <div className="link">
                <NavigationLink menuItem={link} type="iconLink" />
              </div>
            )}
          </div>
        </Container>
        {images && (
          <div className="images">
            {images.map((image, k) => (
              <Img key={`image-${k}`} fluid={image} />
            ))}
          </div>
        )}
      </StyledTestimonials>
    </Animate>
  )
}

export default Testimonials
